import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core";
import "../App.css";
const useStyles = makeStyles((theme) => ({
  helperText: {
    textAlign: "center",
    opacity: "50%",
  },
}));
const styles = {
  avatar: {
    position: "fixed",
    // justifycontent: "center",
    // backgroundrepeat: "no-repeat",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
   
  },
  center: {
    backgroundImage: `url(img/signin/background.png)`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% auto",
    // display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
    // border: "none",
    // height: "100%",
    // minHeight: "100%",
    height:"100vh"
  },
  form: {
    background: "#55acf3",
    padding: "1.5em",
    borderRadius: "1em",
  },

  errorMessage: {
    marginTop: "1em",
    color: "red",
  },
  alignerItem: {
    // maxWidth: "60%",
    // width: "60%",
    // margin: "0px auto",
    paddingTop:"300px"
  },
};

export default function Signin() {
  const classes = useStyles();

  const [username, setUserName] = useState();
  const handleSubmit = async (e) => {
    e.preventDefault();
    //if (e.target.value === undefined) return;
    if(username===undefined) return;
    var userstring = {
      username: `${username}`,
      quiz1: 0,
      quiz2: 0,
      quiz3: 0,
      quiz4: 0,
      quiz5: 0,
      quiz6: 0,
      quiz7: 0,
      quiz8: 0,
      quiz9: 0,
      quiz10: 0,
      quiz11: 0, 
      quiz12: 0,
      quiz13: 0,
      quiz14: 0,
      quiz15: 0,
    };
    let userData = JSON.stringify(userstring);
    localStorage.setItem("json", userData);
    //localStorage.setItem("accessToken", response["accessToken"]);
    //const musername = response["museumuser"];
    //localStorage.setItem("username", JSON.stringify(musername["username"]));
    window.location.href = "/profile";
  };

  return (
    <div style={styles.avatar}>
      <div style={styles.center}>
        {/* <div className="scontainer"> */}
        {/* <div className="scenter"> */}
        <div style={styles.alignerItem}>
          <span
            class="user-name"
            style={{
              fontFamily: "NanumSquareRound",
              fontStyle: "normal",
              fontWeight: "700",
              fontColor: "#5f52b3",
              width: "50%",
              height: "10%",
              fontSize: "120%",
            }}
            gutterBottom
            variant="title"
          >
            퀴즈를 참여하기 전에
            <br />
            별명을 입력해 주세요
            <br />
          </span>
          <form onSubmit={handleSubmit}>
            <TextField
              name="별명"
              inputProps={{ maxLength: 10, style: { textAlign: "center" } }}
              value={username}
              placeholder="별명"
              textAlign="center"
              helperText="10자 이내로 작성해 주세요"
              //onChange={(e) => setUserName(e.target.value)}
              onInput={(e) => {
                //e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,12)
                if (e.target.value.length > 10) return;
                else if (e.target.value === undefined) return;
                setUserName(e.target.value);
              }}
              FormHelperTextProps={{
                className: classes.helperText,
              }}
            />
          </form>
          <br/>
          <button
            style={{ border: "none", background: "none", outline: "none" }}
          >
            <img
              style={{
                width: "40%",
                height: "10%",
              }}
              alt="showquiz"
              src={process.env.PUBLIC_URL + "/img/signin/button.png"}
              onClick={handleSubmit}
            />
          </button>
        </div>
      </div>
    </div>
  );
}
