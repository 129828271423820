import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom'
import Signin from './quiz/Sigin';
import Profile from './quiz/Profile';
import Quiz1 from './quiz/Quiz1';
import Quiz2 from './quiz/Quiz2';
import Quiz3 from './quiz/Quiz3';
import Quiz4 from './quiz/Quiz4';
import Quiz5 from './quiz/Quiz5';
import Quiz6 from './quiz/Quiz6';
import Quiz7 from './quiz/Quiz7';
import Quiz8 from './quiz/Quiz8';
import Quiz9 from './quiz/Quiz9';
import Quiz10 from './quiz/Quiz10';
import Quiz11 from './quiz/Quiz11';
import Quiz12 from './quiz/Quiz12';
import Quiz13 from './quiz/Quiz13';
import Quiz14 from './quiz/Quiz14';
import Quiz15 from './quiz/Quiz15';
import Result from './quiz/Result';
import QRScanner from './quiz/QRScanner';

const App = props => {
  const Islogin = localStorage.getItem('json');
  if (!Islogin) {
    return (
      <div className='App'>
        <Signin />
      </div>
    );

  }

  return (

    <div className='App'>
      <BrowserRouter>
        <Route path="/profile" component={Profile} />
        <Route path="/quiz1" component={Quiz1} />
        <Route path="/quiz2" component={Quiz2} />
        <Route path="/quiz3" component={Quiz3} />
        <Route path="/quiz4" component={Quiz4} />
        <Route path="/quiz5" component={Quiz5} />
        <Route path="/quiz6" component={Quiz6} />
        <Route path="/quiz7" component={Quiz7} />
        <Route path="/quiz8" component={Quiz8} />
        <Route path="/quiz9" component={Quiz9} />
        <Route path="/quiz10" component={Quiz10} />
        <Route path="/quiz11" component={Quiz11} />
        <Route path="/quiz12" component={Quiz12} />
        <Route path="/quiz13" component={Quiz13} />
        <Route path="/quiz14" component={Quiz14} />
        <Route path="/quiz15" component={Quiz15} />
        <Route path="/QRScanner" component={QRScanner} />
        <Route path="/Result" component={Result} />
      </BrowserRouter>
    </div>
  )
}
export default App;