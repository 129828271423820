import React from "react";
import "../App.css";

const styles = {
  avatar: {
    position: "fixed",
    justifycontent: "center",
    backgroundrepeat: "no-repeat",
    width: "100%",
    height: "100vh",
    top: 0,
    left: 0,
  },
  center: {
    backgroundImage: `url(img/profile/background.png)`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "none",
    height: "100%",
  },
  form: {
    background: "#55acf3",
    padding: "1.5em",
    borderRadius: "1em",
  },

  errorMessage: {
    marginTop: "1em",
    color: "red",
  },
  alignerItem: {
    maxWidth: "70%",
    width: "70%",
    margin: "0 auto",
  },
  bold: { fontWeight: "bold" },
  italic: { fontStyle: "italic" },
  underline: { textDecorationLine: "underline" },
};

export default function Profile() {
  var jsonUser = JSON.parse(localStorage.getItem("json"));
  console.log(jsonUser);
  const username = jsonUser.username;
  const handleLogout = () => {
    //localStorage.removeItem("accessToken");
    //localStorage.removeItem("user");
    localStorage.removeItem("username");
    localStorage.removeItem("json");
    window.location.href = "/signin";
  };
  const goarquiz = () => {
    window.location.href = "/QRScanner";
  };
  return (
    <div style={styles.avatar}>
      <div style={styles.center}>
        {/* <div className="scontainer"> */}
        {/* <div className="scenter"> */}
        <div style={styles.alignerItem}>
          <img
            style={{
              width: "80%",
              height: "30%",
              position: "fixed",
              top: 0,
              left: 0,
            }}
            alt="title"
            name="logo"
            src={process.env.PUBLIC_URL + "/img/profile/title.png"}
            width="100"
            height="100"
          ></img>
          <text
            class="user-name"
            style={{
              fontFamily: "NanumSquareRound",
              fontStyle: "normal",
              fontWeight: "700",
              fontColor: "#5f52b3",
              width: "50%",
              height: "10%",
              fontSize: "100%",
              textDecorationLine: "underline",
            }}
            borderBottom="1px"
            gutterBottom
            variant="title"
          >
            <br />
            <br />
            <br />
            {username + " "}
          </text>
          <text
            class="user-name"
            style={{
              fontFamily: "NanumSquareRound",
              fontStyle: "normal",
              fontWeight: "700",
              fontColor: "#5f52b3",
              width: "50%",
              height: "10%",
              fontSize: "110%",
              lineHeight:"1"
            }}
            borderBottom="1px"
            gutterBottom
            variant="title"
          >
            님!
            <br />
            반갑습니다.
            <br />
            <br />
            도자유물 퀴즈는 총 15문제로
            <br />
            모든 문제의 정답을 맞추면 미션에
            <br />
            성공하게 됩니다.
            <br />
            <br />
            박물관 유물 곳곳에 숨어있는
            <br /> 15개의 QR코드를 찾아서
            <br />
            15개의 퀴즈를 풀고 정답을 맞춰서
            <br />
            특별한 기념품을 받아가세요!
          </text>
          {/* <button
            style={{
              width: "50%",
              height: "10%",
              backgroundImage: "url(/img/profile/quizbutton.png)",
              backgroundRepeat: "no-repeat",
            }}
            onClick={handleLogout}
            type="submit"
          >
            logout
          </button> */}
          
          <br />
          <br />
          <br />

          <button style={{ border: "none", background: "none",outline:"none" }}>
            <img
              style={{
                width: "60%",
                height: "20%",
              }}
              alt="showquiz"
              src={process.env.PUBLIC_URL + "/img/profile/quizbutton_d.png"}
              onClick={goarquiz}
            />
          </button>
        </div>
      </div>
    </div>
  );
}
