import SwipeableBottomSheet from "react-swipeable-bottom-sheet";
import Webcam from "react-webcam";
import React, { useEffect, useState } from "react";
import { Dialog, DialogContent, makeStyles } from "@material-ui/core";
import Result from "./Result";
import Fade from "@material-ui/core/Fade";
import Snackbar from "@material-ui/core/Snackbar";
import Grow from "@material-ui/core/Grow";
const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
  },
  instructions: {
    position: "absolute",
    top: "30%",
    left: "50%",
    textAlign: "center",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: "bold",
    fontSize: 24,
    transform: "translate(-50%, -50%)",
    width: "100%",
    color: "white",
  },
  image: {
    width: "100%",
  },
  dialog: {
    width: "100%",
  },
  thanks: {
    padding: 50,
  },
  button: {
    position: "absolute",
    bottom: "10%",
    left: "50%",
    transform: "translate(-50%, 0%)",
  },
  noDisplay: {
    display: "none",
  },
}));
const quizStyles = makeStyles({
  quiz: {
    width: "150px",
    height: "75px",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundColor: "transparent",
    border: "none",
    WebkitTapHighlightColor: "#ffffff",
    WebkitTouchCallout: "none",
    outline: "none",
  },
});
const quiz1num1 = {
  backgroundImage: `url('${process.env.PUBLIC_URL}/img/quiz7/quiz7_num1_default.png')`,
};
const quiz1num1p = {
  backgroundImage: `url('${process.env.PUBLIC_URL}/img/quiz7/quiz7_num1_press.png')`,
};
const quiz1num2 = {
  backgroundImage: `url('${process.env.PUBLIC_URL}/img/quiz7/quiz7_num2_default.png')`,
};
const quiz1num2p = {
  backgroundImage: `url('${process.env.PUBLIC_URL}/img/quiz7/quiz7_num2_press.png')`,
};
const quiz1num3 = {
  backgroundImage: `url('${process.env.PUBLIC_URL}/img/quiz7/quiz7_num3_default.png')`,
};
const quiz1num3p = {
  backgroundImage: `url('${process.env.PUBLIC_URL}/img/quiz7/quiz7_num3_press.png')`,
};
const quiz1num4 = {
  backgroundImage: `url('${process.env.PUBLIC_URL}/img/quiz7/quiz7_num4_default.png')`,
};
const quiz1num4p = {
  backgroundImage: `url('${process.env.PUBLIC_URL}/img/quiz7/quiz7_num4_press.png')`,
};
function GrowTransition(props) {
  return <Grow {...props} />;
}
const Quiz7 = (props) => {
  const classes = useStyles();

  const [result, setResult] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [select, setSelect] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [value, setValue] = React.useState(false);
  const [quiz, setQuiz] = useState("");
  const [state, setState] = React.useState({
    open: false,
    Transition: Fade,
  });
  const styled = quizStyles();
  const size = useWindowSize();
  const isLandscape = size.height <= size.width;
  const ratio = isLandscape
    ? size.width / size.height
    : size.height / size.width;

  function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });

    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }

      // Add event listener
      window.addEventListener("resize", handleResize);

      // Call handler right away so state gets updated with initial window size
      handleResize();

      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount

    return windowSize;
  }
  function getQuiz(value) {
    var jsonUser = JSON.parse(localStorage.getItem('json'));
    jsonUser.quiz7=parseInt(value);
    let userData = JSON.stringify(jsonUser);
    localStorage.setItem('json', userData);
    console.log(jsonUser);
    setQuiz({
      ...quiz,
      quiz7: parseInt(value),
    });
    setSelect(value);
    setValue(value);
    console.log(value);
  }
  const handleDialogOpen = (Transition) => () => {
    if (value === false) {
      setState({
        open: true,
        Transition,
      });
    } else {
      setDialogOpen(true);
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };
  const handleClose = () => {
    setState({
      ...state,
      open: false,
    });
  };
  const onChangeScreen = () => {
    setResult((prev) => !prev);
  };
  if (result) {
    return <Result onChangeScreen={onChangeScreen} />;
  }
  return (
    <div className={classes.root}>
      <Webcam
        audio={false}
        height={size.height}
        width={size.width}
        videoConstraints={{ facingMode: "environment", aspectRatio: ratio }}
        ref={(camera) => (window.camera = camera)}
        //mirrored
      />
      <img
        style={{
          width: "100%",
          height: "20%",
          position: "fixed",
          top: 0,
          left: 0,
        }}
        alt="title"
        name="logo"
        src={process.env.PUBLIC_URL + "/img/quiz7/quiz07_AR_title.png"}
        width="100"
        height="100"
      ></img>
      <button
        style={{ border: "none", background: "none" }}
        text="hi"
        onClick={() => setOpen((prev) => !prev)}
      >
        <img
          style={{
            width: "100%",
            height: "20%",
            position: "fixed", //바텀 고정
            bottom: 0,
            left: 0,
          }}
          alt="showquiz"
          src={process.env.PUBLIC_URL + "/img/common/ShowQuizButton.png"}
        />
      </button>
      <SwipeableBottomSheet
        open={open}
        fullScreen
        onChange={() => setOpen((prev) => !prev)}
      >
       <div
          // className={classes.root}
          style={{
            position: "fixed",
            justifycontent: "top",
            backgroundrepeat: "no-repeat",
            textAlign: "center",
            width: "100%",
            height: "100vh",
            top: 0,
            left: 0,
          }}
        >
          {/* <button style={{border: "none",background: "none",position:"absolute",top:"2%",left:"0%" }}>
              <img
                 style={{
                  width: "30%",
                  height: "30%",
                }}
                alt="backbutton"
                src={process.env.PUBLIC_URL + "/img/common/BackButton_d.png"}
                onClick={() => setOpen((prev) => !prev)}
              />
            </button> */}

          <div
            style={{
              backgroundImage: `url(img/common/combackground.png)`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "100% 100%",
              display: "flex",
              //top:"100%",
              justifyContent: "top",
              alignItems: "center",
              border: "none",
              height: "100%",
            }}
          >
            <button
              style={{
                border: "none",
                background: "none",
                position: "absolute",
                top: "2%",
                left: -80,
              }}
              onClick={() => setOpen((prev) => !prev)}
            >
              <img
                style={{
                  width: "25%",
                  height: "25%",
                }}
                alt="closebutton"
                src={process.env.PUBLIC_URL + "/img/common/BackButton_d.png"}
              />
            </button>
            <div
              style={{
                maxWidth: "100%",
                width: "100%",
                margin: "0 auto",
              }}
            >
              <text
                class="user-name"
                style={{
                  fontFamily: "NanumSquareRound",
                  fontStyle: "normal",
                  fontWeight: "900",
                  fontColor: "#5f52b3",
                  width: "50%",
                  height: "10%",
                  fontSize: "140%",
                }}
                borderBottom="1px"
                gutterBottom
                variant="title"
              >
                도자유물 AR퀴즈
                <br />
                <br />
              </text>
              <text
                class="quiz-name"
                style={{
                  fontFamily: "NanumSquareRound",
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontColor: "#5f52b3",
                  width: "50%",
                  height: "10%",
                  fontSize: "120%",
                }}
                borderBottom="1px"
                gutterBottom
                variant="title"
              >
                <br />
                둥근 백자 항아리는 다음 중 무엇과 
                <br />
                가장 닮았을까요?
                <br />
              </text>
              <br />
              <br />
              <button
                className={styled.quiz}
                type="radio"
                value="1"
                width="40%"
                onClick={(event) => getQuiz(event.target.value)}
                style={select === "1" ? quiz1num1p : quiz1num1}
              />
              <br />

              <button
                className={styled.quiz}
                type="radio"
                value="2"
                width="40%"
                onClick={(event) => getQuiz(event.target.value)}
                style={select === "2" ? quiz1num2p : quiz1num2}
              />
              <br />

              <button
                className={styled.quiz}
                type="radio"
                value="3"
                width="40%"
                onClick={(event) => getQuiz(event.target.value)}
                style={select === "3" ? quiz1num3p : quiz1num3}
              />
              <br />

              <button
                className={styled.quiz}
                type="radio"
                value="4"
                width="40%"
                onClick={(event) => getQuiz(event.target.value)}
                style={select === "4" ? quiz1num4p : quiz1num4}
              />
              <br />
              <br />

              <button
                style={{ border: "none", background: "none", outline: "none" }}
              >
                <img
                  style={{
                    width: "25%",
                    height: "25%",
                  }}
                  alt="showquiz"
                  src={
                    process.env.PUBLIC_URL + "/img/common/ConfirmButton_d.png"
                  }
                  onClick={handleDialogOpen(GrowTransition)}
                />
              </button>
            </div>
          </div>
        </div>
      </SwipeableBottomSheet>
      <Dialog
        style={{ padding: "0% 0% 0% 0%" }}
        open={dialogOpen}
        onClose={handleDialogClose}
        maxWidth={"sm"}
        fullScreen={"fullscreen"}
        fullWidth={true}
      >
        <DialogContent
          class="confirm-background"
          style={{
            padding: "0% 0% 0% 0%",
            backgroundSize: "150% 150%",
            backgroundColor: "transparent",
          }}
        >
          {value === "3" ? (
            <div
              style={{
                position: "fixed",
                justifycontent: "top",
                backgroundrepeat: "no-repeat",
                textAlign: "center",
                /* top: 0px; */
                /* left: 0px; */
                width: "100%",
                height: "100vh",
                top: 0,
                left: 0,
              }}
            >
              <div
                style={{
                  backgroundImage: `url(img/common/background.png)`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100% 100%",
                  display: "flex",
                  //top:"100%",
                  justifyContent: "top",
                  alignItems: "center",
                  border: "none",
                  height: "100%",
                }}
              >
                <button
                  style={{
                    border: "none",
                    background: "none",
                    position: "absolute",
                    top: "2%",
                    right: -80,
                  }}
                >
                  <img
                    style={{
                      width: "25%",
                      height: "25%",
                    }}
                    alt="closebutton"
                    src={
                      process.env.PUBLIC_URL + "/img/common/close_button_d.png"
                    }
                    onClick={handleDialogClose}
                  />
                </button>
                <div
                  style={{
                    maxWidth: "100%",
                    width: "100%",
                    margin: "0 auto",
                  }}
                >
                  <span
                    class="stamp-ox"
                    style={{
                      fontFamily: "NanumSquareRound",
                      fontStyle: "normal",
                      fontWeight: "900",
                      fontColor: "#ff0000",
                      width: "50%",
                      height: "10%",
                      fontSize: "450%",
                    }}
                    gutterBottom
                    variant="title"
                  >
                    정답!
                    <br />
                    <br />
                  </span>
                  <img
                    style={{
                      border: "none",
                      background: "none",
                      width: "40%",
                      height: "40%",
                      backgroundRepeat: "no-repeat",
                    }}
                    src={process.env.PUBLIC_URL + "/img/quiz7/quiz7_O.png"}
                    alt="quiz7O"
                  ></img>
                  <br />
                  <br />

                  <span
                    class="stamp-ox"
                    style={{
                      fontFamily: "NanumSquareRound",
                      fontStyle: "normal",
                      fontWeight: "900",
                      fontColor: "#ff0000",
                      width: "50%",
                      height: "10%",
                      fontSize: "120%",
                      lineHeight:"2"

                    }}
                    gutterBottom
                    variant="title"
                  >
                    정답 스티커를 수집하였습니다.
                   
                    <br />
                    수집한 스티커는 컬렉션 가기를
                    
                    <br />
                    누르면 확인할 수 있습니다.
                   
                    <br />
                  </span>

                  <br />
                  <button style={{ border: "none", background: "none" }}>
                    <img
                      style={{
                        width: "40%",
                        height: "20%",
                      }}
                      alt="showquiz"
                      src={
                        process.env.PUBLIC_URL +
                        "/img/common/collectionbtn_d.png"
                      }
                      onClick={onChangeScreen}
                    />
                  </button>
                </div>
              </div>
            </div>
          ) : value === false ? (
            <div></div>
          ) : (
            <div
              style={{
                position: "fixed",
                justifycontent: "top",
                backgroundrepeat: "no-repeat",
                textAlign: "center",
                /* top: 0px; */
                /* left: 0px; */
                width: "100%",
                height: "100vh",
                top: 0,
                left: 0,
              }}
            >
              <div
                style={{
                  backgroundImage: `url(img/common/background.png)`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100% 100%",
                  display: "flex",
                  //top:"100%",
                  justifyContent: "top",
                  alignItems: "center",
                  border: "none",
                  height: "100%",
                }}
              >
                <button
                  style={{
                    border: "none",
                    background: "none",
                    position: "absolute",
                    top: "2%",
                    right: -80,
                  }}
                >
                  <img
                    style={{
                      width: "25%",
                      height: "25%",
                    }}
                    alt="closebutton"
                    src={
                      process.env.PUBLIC_URL + "/img/common/close_button_d.png"
                    }
                    onClick={handleDialogClose}
                  />
                </button>
                <div
                  style={{
                    maxWidth: "100%",
                    width: "100%",
                    margin: "0 auto",
                  }}
                >
                  <span
                    class="stamp-ox"
                    style={{
                      fontFamily: "NanumSquareRound",
                      fontStyle: "normal",
                      fontWeight: "900",
                      fontColor: "#ff0000",
                      width: "50%",
                      height: "10%",
                      fontSize: "450%",
                    }}
                    gutterBottom
                    variant="title"
                  >
                    오답!
                    <br />
                    <br />
                  </span>
                  <img
                    style={{
                      border: "none",
                      background: "none",
                      width: "40%",
                      height: "40%",
                      backgroundRepeat: "no-repeat",
                    }}
                    src={process.env.PUBLIC_URL + "/img/quiz7/quiz7_X.png"}
                    alt="quiz7X"
                  ></img>
                  <br />
                  <br />

                  <span
                    class="stamp-ox"
                    style={{
                      fontFamily: "NanumSquareRound",
                      fontStyle: "normal",
                      fontWeight: "900",
                      fontColor: "#ff0000",
                      width: "50%",
                      height: "10%",
                      fontSize: "120%",
                      lineHeight:"2"

                    }}
                    gutterBottom
                    variant="title"
                  >
                    오답 스티커를 수집하였습니다.
                  
                    <br />
                    수집한 스티커는 컬렉션 가기를
                    
                    <br />
                    누르면 확인할 수 있습니다.
                    
                    <br />
                  </span>

                  <br />
                  <button style={{ border: "none", background: "none" }}>
                    <img
                      style={{
                        width: "40%",
                        height: "20%",
                      }}
                      alt="showquiz"
                      src={
                        process.env.PUBLIC_URL +
                        "/img/common/collectionbtn_d.png"
                      }
                      onClick={onChangeScreen}
                    />
                  </button>
                </div>
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>
      <Snackbar
        open={state.open}
        onClose={handleClose}
        TransitionComponent={state.Transition}
        message="정답을 선택한 후에 정답확인 버튼을 눌러 주세요"
        alignItems="center"
        key={state.Transition.name}
      />
    </div>
  );
};

export default Quiz7;
//완료
