import React, { useEffect, useState } from "react";
import Scanner from "react-webcam-qr-scanner";

const QRScanner = (props) => {
  const size = useWindowSize();
  //const isLandscape = size.height <= size.width;
  // const ratio = isLandscape
  //   ? size.width / size.height
  //   : size.height / size.width;

  console.log(size);
  function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });

    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }

      // Add event listener
      window.addEventListener("resize", handleResize);

      // Call handler right away so state gets updated with initial window size
      handleResize();

      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount

    return windowSize;
  }

  const handleDecode = (data) => {
    if (data === "") {
    } else {
      // this.setState({
      //   result: data.data
      // })
      console.log(data.data);
      window.location.replace(data.data);
    }
    console.log(data);
    console.log(data.data);
  };

  const handleScannerLoad = (mode) => {
    console.log(mode);
  };

  return (
    <div style={{
      position: "fixed",
    }}>
      <Scanner
        height={size.height}
        //width={size.width}
        className="some-classname"
        onDecode={handleDecode}
        onScannerLoad={handleScannerLoad}
        constraints={{
          audio: false,
          video: {
            facingMode: "environment",
            //aspectRatio: ratio,
          },
        }}
        //captureSize={size}
      />
    </div>
  );
};
export default QRScanner;
