import React, { useEffect, useState } from "react";
import "../App.css";
import { Dialog, DialogContent } from "@material-ui/core";
import html2canvas from "html2canvas";
const styles = {
  center: {
    backgroundImage: `url(img/profile/background.png)`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 60%",
    justifyContent: "top",
    alignItems: "top",
    border: "none",
    height: "100",
  },
  allstamp: {
    backgroundImage: `url(img/profile/background.png)`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "top",
    border: "none",
    width: "100%",
    height: "100%",
    position: "fixed",
  },
  // allblank: {
  //   backgroundImage: `url(img/result/blank.png)`,
  //   alignItems: "center",
  //   justifyContent: "center",
  //   position: "fixed",
  //   width: "100%",
  //   height: "100vh",
  //   top: 0,
  //   left: 0,
  //   backgroundRepeat: "no-repeat",
  //   backgroundSize: "100% 100%",
  // },
  alignerItem: {
    maxWidth: "100%",
    width: "100%",
    margin: "0 auto",
  },
  icon: { display: "none" },
};

const Result = (props) => {
  const [allStamp, setallStamp] = useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [quiz, setQuiz] = useState([]);
  const [capture, setCaputre] = useState();
  //const user = JSON.parse(localStorage.getItem("username"));
  const json = JSON.parse(localStorage.getItem("json"));
  //const user = JSON.parse(localStorage.getItem("username"));
  const user = json.username
  let makerpostion = [
    {
      top: "185px",
      left: "16%",
      position: "absolute",
      width: "145px",
      height: "10%",
      zIndex:"1"
    }, //1번문제 정답 오답 스티커 위치 맞춤
    {
      top: "185px",
      left: "62.5%",
      position: "absolute",
      width: "85px",
      height: "13%",
      zIndex:"1"

    }, //2번문제 정답 오답 스티커 위치 맞춤
    {
      top: "230px",
      left: "21%",
      position: "absolute",
      width: "85px",
      height: "13%",
      zIndex:"1"

    }, //3번문제 정답 오답 스티커 위치 맞춤
    {
      top: "42%",
      left: "12%",
      position: "absolute",
      width: "145px",
      height: "13%",
      zIndex:"1"

    }, //4번문제 정답 오답 스티커 위치 맞춤
    {
      top: "52.0%",
      left: "10%",
      position: "absolute",
      width: "85px",
      height: "12%",
      zIndex:"1"

    }, //5번문제 정답 오답 스티커 위치 맞춤(주황네모)
    {
      top: "46.5%",
      left: "49.6%",
      position: "absolute",
      width: "85px",
      height: "1050x",
      zIndex:"1"

    }, //6번문제 정답 오답 스티커 위치 맞춤(노란철) 보류
    {
      top: "37%",
      left: "66%",
      position: "absolute",
      width: "85px",
      height: "13%",
      zIndex:"1"

    }, //7번문제 정답 오답 스티커 맞춤(주황육각) 보류
    {
      top: "55.5%",
      left: "28%",
      position: "absolute",
      width: "85px",
      height: "85px",
      zIndex:"1"

    }, //8번문제 정답 오답 스티커 맞춤(모란) 보류
    {
      top: "61%",
      left: "10%",
      position: "absolute",
      width: "80px",
      height: "17%",
      zIndex:"1"

    }, //9번문제 정답 오답 스티커 맞춤(길쭉)
    {
      top: "70.2%",
      left: "22.8%",
      position: "absolute",
      width: "120px",
      height: "11%",
      zIndex:"1"

    }, //10번문제 정답 오답 스티커 맞춤(구름)
    {
      top: "63.7%",
      left: "41%",
      position: "absolute",
      width: "85px",
      height: "85px",
      zIndex:"1"

    }, //11번문제 정답 오답 스티커 맞춤(태양)
    {
      top: "50.5%",
      left: "65%",
      position: "absolute",
      width: "100px",
      height: "70px",
      zIndex:"1"

    }, //12번문제 정답 오답 스티커 (레몬)
    {
      top: "55.5%",
      left: "58%",
      position: "absolute",
      width: "125px",
      height: "85px",
      zIndex:"1"

    }, //13번문제 정답 오답 스티커 (당고)
    {
      top: "65.3%",
      left: "57.0%",
      position: "absolute",
      width: "133px",
      height: "15%",
      zIndex:"1"

    }, //14번문제 정답 오답 스티커 얘도 위치 맞춤
    {
      top: "245px",
      left: "51.5%",
      position: "absolute",
      width: "85px",
      height: "13%",
      zIndex:"1"

    }, //15번문제 정답 오답 스티커 위치 맞춤
  ];
  let answers = [4, 2, 1, 3, 1, 4, 3, 2, 4, 2, 2, 3, 1, 3, 4];
  const onChangeScreen = () => {
    window.location.href = "/QRScanner";
    //window.location.href = "/ARQuiz";
  };
  const handleDialogOpen = () => {
    onScreenShot2();
    setDialogOpen(true);
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  // const onScreenShot = () => { //바로 다운로드로 저장
  //   // window.scrollTo(0, 0);

  //   // let url = "";
  //   // await html2canvas(document.getElementById("complete")).then(async (canvas) => {
  //   //     url = await canvas.toDataURL("image/jpg").split(',')[1];
  //   //     //setOpen(true);
  //   // });
  //   console.log("onCapture");
  //   // html2canvas(document.getElementById('div')).then(canvas => {
  //   //     onSavaAs(canvas.toDataURL('image/png', 'image-download.png')
  //   // })
  //   html2canvas(document.getElementById("complete")).then((canvas) => {
  //     onSaveAs(canvas.toDataURL("image/png"), "image-download.png");
  //   });
  //   //await uploadImgur(url);
  //   // html2canvas(document.body).then(function(canvas) {
  //   //   document.body.appendChild(canvas);  }  );
  // };
  const onScreenShot2 = () => {
    console.log("onCapture");
    html2canvas(document.getElementById("complete")).then((canvas) => {
      var data = canvas.toDataURL();

      let capturedata = () => {
        console.log(data);
        let result = data;

        if (result === "") {
          return <div />;
        } else {
          return (
            <img alt="captureimage" width="100%" height="100%" src={result} />
          );
        }
      };

      setCaputre(capturedata);
    });
  };
 
  // const onSaveAs = (uri, filename) => { // 다운로드에 저장
  //   console.log("onSaveAs");
  //   var link = document.createElement("a");
  //   document.body.appendChild(link);
  //   link.href = uri;
  //   link.download = filename;
  //   link.click();
  //   document.body.removeChild(link);
  // };
  //let isP = Boolean(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const json = JSON.parse(localStorage.getItem("json"));
        //const user = JSON.parse(localStorage.getItem("username"));
        //const user = json.username
        console.log(json.username);
        let quizs = Array.from(Array(15).keys()).map((index) => {
          let idx = index + 1;
          let val = json["quiz" + idx];
          console.log(idx, val);
          let result = "";
          var i = 0;
          for (i = 0; i < answers.length; i++) {
            if (answers[i] === json[`quiz${i + 1}`]) {
              setallStamp(true);
              //isP = true;
            } else {
              setallStamp(false);
              //isP = false;
              break;
            }
          }
          //if (!isP) {
          if (0 === val) {
            result = `${process.env.PUBLIC_URL}/img/result/q${index + 1}b.png`;
            makerpostion[index].zIndex = "0";
          } else if (answers[index] === val) {
            result = `${process.env.PUBLIC_URL}/img/result/q${index + 1}o.png`;
            if(index===14)
            {
              makerpostion[index].zIndex = "1";
            }else{

              makerpostion[index].zIndex = "2";
            }
          } else {
            result = `${process.env.PUBLIC_URL}/img/result/q${index + 1}x.png`;
            if(index===14)
            {
              makerpostion[index].zIndex = "1";
            }else{

              makerpostion[index].zIndex = "2";
            }
          }
          if (result === "") {
            return <div />;
          } else {
            return (
              <img
                alt="stampimage"
                width="100"
                height="100"
                style={makerpostion[index]}
                name={index}
                src={result}
              />
            );
          }
          //}
        });
  
        //JSON.parse를 이용해 jsontext를 파싱해 contact 변수에 넣어준다.
        //var contact = Object.values(json);
        //for문을 돌면서 contact[i]의 값을 출력한다.
        //var contactarr = contact.slice(2, undefined);
        setQuiz(quizs);
        //console.log(contact);
      } catch (error) {
        console.log("error", error);
      }
    };
  fetchData();
  }, []);
  if (allStamp === true) {
    return (
      <div>
        <div style={styles.allstamp} id="complete">
          <div style={styles.alignerItem}>
          {quiz.map((value, index) => {
              return value;
            })}
            <span
              class="user-name"
              style={{
                fontFamily: "NanumSquareRound",
                fontStyle: "normal",
                fontWeight: "900",
                fontColor: "#5f52b3",
                width: "50%",
                height: "10%",
                fontSize: "180%",
              }}
              gutterBottom
              variant="title"
            >
              <br />
              <br />
              스티커 컬렉션
              <br />
            </span>
            <br />
            <text
              class="user-name"
              style={{
                fontFamily: "NanumSquareRound",
                fontStyle: "normal",
                fontWeight: "700",
                fontColor: "#5f52b3",
                width: "50%",
                height: "10%",
                fontSize: "120%",
                textDecorationLine: "underline",
              }}
              borderBottom="1px"
              gutterBottom
              variant="title"
            >
              {user + " "}
            </text>
            <text
              class="user-name"
              style={{
                fontFamily: "NanumSquareRound",
                fontStyle: "normal",
                fontWeight: "700",
                fontColor: "#5f52b3",
                width: "50%",
                height: "10%",
                fontSize: "120%",
              }}
              borderBottom="1px"
              gutterBottom
              variant="title"
              setTouchState="false"
            >
              님. 미션완료!
              <br />
              모든 퀴즈의 스티커를 획득하셨습니다!
              <br />
              <br />
            </text>
            <img
              style={{
                top: "20%",
                left: "10%",
                width: "90%",
                height: "58%",
                //position:"absolute"
              }}
              alt="blankbackground"
              src={process.env.PUBLIC_URL + "/img/result/blank.png"}
              //url(img/result/blank.png)
            />
          {/* <button
              style={{
                border: "none",
                backgroundImage: `url(/img/result/phonesave_d.png)`,
                backgroundSize: "100%",
                backgroundRepeat: "no-repeat",
                backgroundColor: "transparent",
                width: "170px",
                height: "80px",
                top: "87%",
                left: "30%",
                position: "absolute",
              }}
              onClick={handleDialogOpen}
            ></button> */}
           
          </div>
        </div>
        
        <button
              style={{
                border: "none",
                backgroundImage: `url(/img/result/phonesave_d.png)`,
                backgroundSize: "100%",
                backgroundRepeat: "no-repeat",
                backgroundColor: "transparent",
                width: "170px",
                height: "80px",
                top: "87%",
                left: "30%",
                position: "fixed",
                outline:"none"
              }}
              onClick={handleDialogOpen}
            ></button>
        <Dialog
          style={{ padding: "0% 0% 0% 0%" }}
          open={dialogOpen}
          onClose={handleDialogClose}
          maxWidth={"sm"}
          fullWidth={true}
        >
          <DialogContent
            style={{
              padding: "0% 0% 0% 0%",
              backgroundSize: "150% 150%",
              backgroundColor: "transparent",
            }}
          >
            {/* <div
              style={{
                position: "fixed",
                justifycontent: "top",
                backgroundrepeat: "no-repeat",
                textAlign:"center",
                width: "100%",
                height: "100vh",
                top: 0,
                left: 0,
              }}          > */}
            <div
              style={{
                backgroundImage: `url(img/common/background.png)`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% 100%",
                //display: "flex",
                //top:"100%",
                backgroundColor: "transparent",
                justifyContent: "top",
                alignItems: "center",
                border: "none",
                height: "100%",
              }}
            >
              <button
                style={{
                  border: "none",
                  background: "none",
                  position: "absolute",
                  top: "2%",
                  //left: "80%",
                  right: -80,
                }}
              >
                <img
                  style={{
                    width: "25%",
                    height: "25%",
                  }}
                  alt="closebutton"
                  src={
                    process.env.PUBLIC_URL + "/img/common/close_button_d.png"
                  }
                  onClick={handleDialogClose}
                />
              </button>
              {/* <div
                style={{
                  maxWidth: "100%",
                  width: "100%",
                  margin: "0 auto",
                }}
              > */}
              {capture}
              <br />
              {/* <div
          style={{
            top:"80%",
            left:"30%",
            width: "40%",
            height: "15%",
            position: "absolute",
            backgroundImage: "url(/img/result/longsave.png)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
            border:"none",
            setTouchState:"false",
            backgroundColor:"transparent"
          }}
          >
         
          </div> */}

              {/* </div> */}
            </div>
            {/* </div> */}
            <div id="overlay">
              <div
                id="savePrompt"
                style={{
                  top: "85%",
                  left: "50%",
                  width: "40%",
                  height: "12%",
                  backgroundImage: "url(/img/result/longsave.png)",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100% 100%",
                  border: "none",
                  backgroundColor: "transparent",
                }}
              >
                {/* "길게 눌러서"
                <br />
                "저장하기" */}
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  } else {
    return (
      <div style={styles.allstamp}>
        <div style={styles.alignerItem}>
          {quiz.map((value, index) => {
            return value;
          })}
          <span
            class="user-name"
            style={{
              fontFamily: "NanumSquareRound",
              fontStyle: "normal",
              fontWeight: "900",
              fontColor: "#5f52b3",
              width: "50%",
              height: "10%",
              fontSize: "180%",
            }}
            gutterBottom
            variant="title"
          >
            <br />
            <br />
            스티커 컬렉션
            <br />
          </span>
          <br />
          <span
            class="user-name"
            style={{
              fontFamily: "NanumSquareRound",
              fontStyle: "normal",
              fontWeight: "700",
              fontColor: "#5f52b3",
              width: "50%",
              height: "10%",
              fontSize: "120%",
            }}
            gutterBottom
            variant="title"
          >
            다른 위치에 있는 QR코드를 찾아
            <br />
            계속해서 퀴즈에 도전해 주세요!
            <br />
          </span>
          <img
            style={{
              top: "10%",
              left: "10%",
              width: "90%",
              height: "58%",
              //position:"absolute"
            }}
            alt="blank"
            src={process.env.PUBLIC_URL + "/img/result/blank.png"}
            //url(img/result/blank.png)
          />
            <br />
            <br />
          <button
            style={{
              border: "none",
              backgroundImage: `url(/img/result/continuebutton_d.png)`,
              backgroundSize: "100%",
              backgroundRepeat: "no-repeat",
              backgroundColor: "transparent",
              width: "170px",
              height: "120px",
              top: "87%",
              left: "30%",
              outline:"none"
              //position: "absolute",
            }}
            onClick={onChangeScreen}
          ></button>
        </div>
      </div>
    );
  }
};

export default Result;
